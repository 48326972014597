<template>
    <div>
      <h2>Update Marquee Text</h2>
      <textarea v-model="newText" rows="4" cols="50"></textarea>
      <button @click="updateMarqueeText">Update</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        newText: '',
      };
    },
    methods: {
      async updateMarqueeText() {
        try {
          const response = await fetch('http://localhost:3000/api/marquee-text', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: this.newText }),
          });
          const data = await response.json();
          alert('Marquee text updated to: ' + data.text);
        } catch (error) {
          console.error('Error updating marquee text:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Your styles here */
  </style>