export const testimonials = [
{
    id: 1,
    name: 'Christine Rudd',
    image: require('@/assets/Review 1.png'),
    description: "Great company to have do your roof! Our roof was done in a day! They laid tarps to catch any debris & to protect our decks while taking off the roof. They did a great job cleaning up. Any questions we had were answered.",
    ratings: 5,
},
{
    id: 2,
    name: 'Ryan Schebel',
    description: "Great company! Called for a simple roof vent repair that other companies didn't call back or even show up for. Brett responded right away and was out asap to fix for less than I expected. Keeping Homes Roofing number for any roof needs!",
    ratings: 5,
},
{
    id: 3,
    name: 'Joe Messenger',
    description: "Their quote was fair and more favorable than most, they worked us in fairly quickly, they were prompt and cleaned up after themselves. The finished product looks great, I'm very pleased with their work and would recommend them to others!",
    ratings: 5,
},
{
    id: 4,
    name: 'Dave Roden',
    description: "Highly recommend for your roofing needs. Second to none with fast, responsive and open service.",
    ratings: 5,
},
{
    id: 5,
    name: 'Constance',
    description: "I hired Homes roofing last fall. They removed the old shingles, replaced some decking, installed the new roofing, and cleaned up in one day. The whole team was professional, friendly, hard working. I am very pleased with how my new roof is holding up and how it looks.",
    ratings: 5,
},
{
    id: 6,
    name: 'Sharilyn Burrow',
    description: "Very impressed by this company and will definitely recommend them to anyone needing roof work.  The owner has a very nice personality; never pushy or arrogant (like some of the others) and he was always honest and upfront during the entire process.  He sent photos to us of any issues that arose so we knew exactly why it needed attention and how much it would cost.  His estimate was so much better than the dozen others we contacted.  He broke down every item needed along with the cost and possible scenarios so we wouldn't be caught off guard.  He did not try to upsell us a grade of shingles we did not need and would have cost thousands more (also something the other roofers did)  The crew tarped around our entire house and cleaned up any debris that got away from the tarps.  Our roof decking needed a lot of replacement's before they could shingle but they still finished everything in two days.The most important part is our roof looks amazing!!",
    ratings: 5,
},
{
    id: 7,
    name: 'Kelly King',
    description: "A dream come true! Thanks to HOMES Roofing! Job well done. Easy 5 star rating! Highly recommended. Fast, precise and friendly. You can’t go wrong with HOMES Roofing!",
    ratings: 5,
},
{
    id: 8,
    name: 'Wynne Baumgartner',
    description: "Brett and his crew did a great job for a fair price. I highly recommend HOMES Roofing.  We had a tear off, a new roof, and new trim.They were professional, on time, and did everything we asked for.  Our trim has a lot of detail work and Sergio did an amazing job replacing it.  My house looks fantastic and I will recommend HOMES Roofing to everyone.",
    ratings: 5,
},
{
    id: 9,
    name: 'Justin Henderson',
    description: "Extremely satisfied with the finished product of my new roof! 10/10 Anyone looking for a new roof replacement I strongly recommend going to these guys, you won’t be disappointed!",
    ratings: 5,
},
{
    id: 10,
    name: 'Sarah Duncan',
    description: "Brett made the process of replacing our roof simple. He explained every step of the way in advance and made sure everything happened smoothly and correctly. He took the time to show us many options for shingle style and color, even explaining the pros and cons of each option. The crew was professional and incredibly fast, our roof was removed and the new one put on in one day! Brett was even available for follow up questions after the replacement and helped us with a repair after a storm. Highly recommend this company!",
    ratings: 5,
},
{
    id: 11,
    name: 'Sherri Kolar',
    description: "We needed our roof repaired and had several estimates. Bret made this job both pleasant and affordable.They had to re-deck completely and it was still under the price of the other companies.We are very happy with our new roof. Thank you H.O.M.E.S. Roofing Co.",
    ratings: 5,
},
{
    id: 12,
    name: 'Justin Guske',
    description: "Excellent and quick service with these guys, Brett and his team are great to work with and got our roof done on time and looks amazing.",
    ratings: 5,
},
{
    id: 13,
    name: 'Derek S',
    description: "10/10, would recommend for all your exterior needs! Brett and his team recently removed and replaced our soffit and fascia. They did a great job and were very professional! Will be using them for our siding next.",
    ratings: 5,
},
{
    id: 14,
    name: 'Tim Frucci',
    description: "Very professional, Brett kept us updated with every phase of the job from when the dumpster would arrive to when they would start the job. Even after about a hour rain delay the crew got right back at it. Would highly recommend them!",
    ratings: 5,
},
]