<template>
    <div class="testimonial-row">
        <div class="testimonialSection" v-for="testimonial in randomTestimonials" :key="testimonial.id">
            <div class="testimonialTop">
            <div class="testimonialNameRating">
                
                <h2>{{ testimonial.name }}</h2>
            <div class="google-rating">
                <span v-for="rating in testimonial.ratings" :key="rating" class="star"></span>
            </div>
            </div>
                
            </div>

            <div class="testimonialDescription">{{ testimonial.description }}</div>

        </div>
    </div>
</template>




<style scoped>
.testimonial-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
</style>

<script>

import {testimonials as Importedtestimonials} from '../components/TestimonialsRepo.js';

export default {
    name: 'CustomerTestimonials',
    data() {
        return {
            testimonials: Importedtestimonials
        };
    },

    computed: {
        randomTestimonials() {
            let testimonials = [...this.testimonials]; // Create a copy of the testimonials array
            for (let i = testimonials.length - 1; i > 0; i--) { // Shuffle the array
                let j = Math.floor(Math.random() * (i + 1));
                [testimonials[i], testimonials[j]] = [testimonials[j], testimonials[i]];
            }
            return testimonials.slice(0, 4); // Return the first 4 elements
        },
    },
};
</script>

<style scoped>
/* Desktop Styling */
.testimonial-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-image: linear-gradient(to right, #002655, #89c2fc);
    color: #f0f0f0;
    padding: 10px
}
    .google-rating {
        display: flex;
    }.star {
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  margin: 0 2px;
  clip-path: polygon(
    50% 0%, 
    61% 35%, 
    98% 35%, 
    68% 57%, 
    79% 91%, 
    50% 70%, 
    21% 91%, 
    32% 57%, 
    2% 35%, 
    39% 35%
  );
}
    .testimonialNameRating {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .testimonialSection {
        width: 30%;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        background-color: #f0f0f0;
        color: #002655;
    }
    .testimonialSection {
        width: 45%;
        padding: 10px;
        
    }
    .testimonialDescription {
        padding-top: 10px;
        font-weight: bold;
    }

    @media (min-width: 880px) {
        .testimonialSection {
            transition: transform 0.3s ease-in-out;
        }
        .testimonialSection:hover {
        transform: scale(1.05); /* Scale up the container by 5% when hovered over */
    }
    }



/* Mobile Styling */
@media (max-width: 880px) {

    .testimonialSection {
        width: 80%;
        padding: 10px;
        
    }

    .testimonialTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
    }
    .testimonial-row {
        display: flex;
        flex-direction: column;
        align-items: center;
         font-family: 'Lato', sans-serif; /* Use Lato font */
    }
    .testimonialNameRating {
        width: 100%;
        
        h2{
            font-size: 1.5em;
            margin-bottom: 10px;
        }
    }
    .google-rating{
        margin-bottom: 10px;
    }


}
</style>


