<template>
    <div class="marquee__content">
      <span style="font-weight: bold;">{{ marqueeText }}</span>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        marqueeText: '',
      };
    },
    created() {
      this.fetchMarqueeText();
    },
    methods: {
      async fetchMarqueeText() {
        try {
          const response = await fetch('http://localhost:3000/api/marquee-text');
          const data = await response.json();
          this.marqueeText = data.text;
        } catch (error) {
          console.error('Error fetching marquee text:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .marquee__content {
    /* Your styles here */
  }
  </style>