<template>
    <div>
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div>
          <label for="username">Username:</label>
          <input type="text" v-model="username" required />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        error: ''
      };
    },
    methods: {
      login() {
        const validUsername = 'admin';
        const validPassword = 'password';
  
        if (this.username === validUsername && this.password === validPassword) {
          localStorage.setItem('authenticated', 'true');
          this.$router.push('/update-marquee-text');
        } else {
          this.error = 'Invalid username or password';
        }
      }
    }
  };
  </script>